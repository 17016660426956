import { graphql, Link, PageProps } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Contact from "../components/contact"
import Layout from "../components/layout"
import SEO from "../components/seo"

export const query = graphql`
    query {
        team: file(relativePath: { eq: "team2020.jpg" }) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
    }
`

interface Data {
    team: { childImageSharp: { gatsbyImageData: IGatsbyImageData } }
}

const IndexPage: React.FC<PageProps<Data>> = ({ data }) => (
    <Layout>
        <SEO title="Showcase Softball" />
        <section className="hero is-dark">
            <GatsbyImage
                image={data.team.childImageSharp.gatsbyImageData}
                alt="Central Mass Thunder"
                className="hero-body hero-img-cover"
            />
        </section>

        <section className="section">
            <div className="container">
                <div className="columns">
                    <div className="column is-two-thirds">
                        <div className="title has-text-centered">Central Mass Thunder Gold 18U</div>
                        {/* <article className="message is-info">
                            <div className="message-header">
                                <p>Tryouts 2022-2023</p>
                            </div>
                            <div className="message-body">
                                Central Mass Thunder will only be holding small/private tryouts for the 2022-2023 season. If you are
                                interested, please contact us using the form on this page. Or by reaching out to one of our <Link to="/coaches">coaches</Link>.
                            </div>
                        </article> */}
                        <p className="has-text-justified">
                            Central Mass Thunder Gold Softball is a uniquely experienced program where we have former college
                            players teaching the mental and physical aspects of softball. All coaches have played or coached at a
                            highly competitive level. The goal of our program is to create a space for young women who are
                            committed to striving for the highest level of competition in softball to enhance their character,
                            mental and physical health, sportsmanship and educational opportunities.
                        </p>
                        <p className="mt-4 has-text-justified">
                            Our program travels locally based on where our players interests are. If you go to our alumni tab you
                            can see where our past players have landed throughout the years. We hold winter practices at Wheaton
                            College and practice two days a week in the summer focusing on fielding and hitting separately to
                            enhance both parts of the game.
                        </p>
                    </div>
                    <div className="column" id="contact">
                        <Contact />
                    </div>
                </div>
            </div>
        </section>
    </Layout>
)

export default IndexPage

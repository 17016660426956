import React from "react"

const Contact: React.FC = () => {
    return (
        <>
            <div className="title has-text-centered">Contact Us</div>
            <form name="contact" method="POST" data-netlify={true} netlify-honeypot="bot-field" action="/coaches">
                <input type="hidden" name="form-name" value="contact" />
                <div className="field is-hidden">
                    <div className="control">
                        <input className="input" name="bot-field" type="text" />
                    </div>
                </div>
                <div className="field">
                    <div className="control">
                        <input className="input" name="name" type="text" placeholder="Name" />
                    </div>
                </div>
                <div className="field">
                    <div className="control has-icons-left has-icons-right">
                        <input className="input" name="email" type="email" placeholder="Email" />
                        <span className="icon is-small is-left">
                            <i className="fas fa-envelope"></i>
                        </span>
                    </div>
                </div>
                <div className="field">
                    <div className="control">
                        <textarea className="textarea" name="message" placeholder="Message"></textarea>
                    </div>
                </div>
                <div className="control">
                    <button className="button is-link" type="submit">
                        Submit
                    </button>
                </div>
            </form>
        </>
    )
}

export default Contact
